import React from "react"
import Layout from "../components/layout"
import "../styles/mains.scss"

const Privacy = () => (
  <Layout>
    <div className="section">
      <div className="container">
        <div className="inner-container">
          <h2>Privacy Policy</h2>
          <div>
            <p>THIS PRIVACY POLICY DESCRIBES OUR POLICIES ON THE COLLECTION, USE, AND DISCLOSURE OF INFORMATION ABOUT YOU IN CONNECTION WITH YOUR USE OF OUR SERVICES, INCLUDING THOSE OFFERED THROUGH OUR WEBSITES, COMMUNICATIONS (E.G., EMAILS, PHONE CALLS, AND TEXTS), AND MOBILE APPLICATIONS (COLLECTIVELY, THE &ldquo;SERVICE&rdquo;). THE TERMS &ldquo;WE&rdquo; AND &ldquo;US&rdquo; REFER TO &ldquo;FEAST&rdquo;.</p>
            <p>WHEN YOU USE THE SERVICE, YOU CONSENT TO OUR COLLECTION, USE, AND DISCLOSURE OF INFORMATION ABOUT YOU AS DESCRIBED IN THIS PRIVACY POLICY.</p>
          </div>
          <p />

          <p>1. INFORMATION WE COLLECT AND HOW WE USE IT</p>
          <p>WE MAY COLLECT, TRANSMIT, AND STORE INFORMATION ABOUT YOU IN CONNECTION WITH YOUR USE OF THE SERVICE, INCLUDING ANY INFORMATION YOU SEND TO OR THROUGH THE SERVICE. WE USE THAT INFORMATION TO PROVIDE THE SERVICE&rsquo;S FUNCTIONALITY, FULFILL YOUR REQUESTS, IMPROVE THE SERVICE&rsquo;S QUALITY, ENGAGE IN RESEARCH AND ANALYSIS RELATING TO THE SERVICE, PERSONALIZE YOUR EXPERIENCE, TRACK USAGE OF THE SERVICE, PROVIDE FEEDBACK TO THIRD PARTY BUSINESSES THAT ARE LISTED ON THE SERVICE, DISPLAY RELEVANT ADVERTISING, MARKET THE SERVICE, PROVIDE CUSTOMER SUPPORT, MESSAGE YOU, BACK UP OUR SYSTEMS, ALLOW FOR DISASTER RECOVERY, ENHANCE THE SECURITY OF THE SERVICE, AND COMPLY WITH LEGAL OBLIGATIONS. EVEN WHEN WE DO NOT RETAIN SUCH INFORMATION, IT STILL MUST BE TRANSMITTED TO OUR SERVERS INITIALLY AND STORED LONG ENOUGH TO PROCESS.</p>
          <div>
            <p>A.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;ACCOUNT INFORMATION: WHEN YOU CREATE A FEAST ACCOUNT, WE STORE AND USE THE INFORMATION YOU PROVIDE DURING THAT PROCESS, SUCH AS THE FIRST AND LAST NAME YOU ENTER, EMAIL ADDRESS, PHONE NUMBER, AND ANY OTHER INFORMATION YOU MAY PROVIDE DURING THE ACCOUNT CREATION PROCESS. WE MAY PUBLICLY DISPLAY THE USERNAME, FIRST NAME, AND LAST NAME THAT YOU PROVIDE, AS WELL AS ANY PHOTO OR OTHER CONTENT YOU SUBMIT THROUGH THE ACCOUNT CREATION PROCESS, AS PART OF YOUR ACCOUNT PROFILE. WE ALSO STORE AND USE ANY PREFERENCES YOU PROVIDE TO PERSONALIZE YOUR USER EXPERIENCE, INCLUDING DIETARY RESTRICTIONS. YOU CAN LATER MODIFY SOME OF THE ACCOUNT INFORMATION YOU PROVIDE THROUGH YOUR ACCOUNT SETTINGS.</p>
            <p>B.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;PUBLIC CONTENT: YOUR CONTRIBUTIONS TO THE SERVICE ARE INTENDED FOR CONSUMPTION BY YOUR FOLLOWERS AND--IN AGGREGATE, DE-IDENTIFIED FORM--THE PUBLIC. THESE CONTRIBUTIONS INCLUDE YOUR RATINGS, NOTES, FAVORITE DISHES, TAGS, PROFILE, AND LISTS.</p>
            <p>C.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;CONTACTS: YOU CAN INVITE OTHERS TO JOIN OR BECOME YOUR FRIEND ON FEAST BY PROVIDING US WITH THEIR CONTACT INFORMATION, OR BY ALLOWING US TO ACCESS CONTACTS FROM YOUR MOBILE DEVICE OR THIRD PARTY SITES TO SELECT WHICH INDIVIDUALS YOU WANT TO INVITE. IF YOU ALLOW US TO ACCESS YOUR CONTACTS, WE MAY USE THIS INFORMATION TO ENHANCE YOUR EXPERIENCE IN VARIOUS WAYS, INCLUDING, BUT NOT LIMITED TO, NOTIFYING YOU WHEN A CONTACT HAS JOINED THE WAITLIST OR THE SERVICE. IN ADDITION, OTHER USERS WHO HAVE YOUR CONTACT INFORMATION AND HAVE CHOSEN TO UPLOAD, SYNC, OR IMPORT IT FROM THEIR DEVICE MAY BE NOTIFIED OF WHEN YOU JOIN OUR SERVICE; THEY MAY BE ABLE TO KNOW THE NUMBER OF PEOPLE ON THE SERVICE WHO HAVE YOUR NUMBER IN THEIR UPLOADED CONTACTS, SO THAT THEY CAN CHOOSE TO INVITE PEOPLE WITH MANY FRIENDS ALREADY ON THE SERVICE. FINALLY, MAY WE USE YOUR LIST OF CONTACTS (IF YOU CHOOSE TO PROVIDE US WITH ACCESS TO THEM) TO RECOMMEND OTHER USERS YOU MIGHT WANT TO FOLLOW AND TO RECOMMEND YOUR ACCOUNT AND CONTENT TO OTHERS.</p>
            <p>D.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;COMMUNICATIONS: WHEN YOU SIGN UP FOR AN ACCOUNT OR USE CERTAIN FEATURES, YOU ARE OPTING TO RECEIVE MESSAGES FROM OTHER USERS, AND FEAST. YOU CAN MANAGE SOME OF YOUR MESSAGING PREFERENCES THROUGH APP SETTINGS AND YOUR DEVICE SETTINGS.</p>
            <p>E.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;ACTIVITY: WE STORE INFORMATION ABOUT YOUR USE OF THE SERVICE, SUCH AS YOUR CLICKS, SEARCH ACTIVITY, THE PAGES YOU VIEW, AND THE DATE AND TIME OF YOUR VISITS.&nbsp;</p>
          </div>

          <p>2. THIRD PARTIES</p>
          <p>THIRD PARTIES MAY RECEIVE INFORMATION ABOUT YOU AS FOLLOWS:</p>
          <div>
            <p>A.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;ADVERTISERS: WE MAY ALLOW THIRD PARTIES TO USE COOKIES THROUGH THE SERVICE TO COLLECT THE SAME TYPE OF INFORMATION FOR THE SAME PURPOSES AS WE DO. IN DOING SO, WE ADHERE TO THE DIGITAL ADVERTISING ALLIANCE&rsquo;S SELF-REGULATORY PRINCIPLES FOR ONLINE BEHAVIORAL ADVERTISING. THIRD PARTIES MAY BE ABLE TO ASSOCIATE THE INFORMATION THEY COLLECT WITH OTHER INFORMATION THEY HAVE ABOUT YOU FROM OTHER SOURCES. WE DO NOT NECESSARILY HAVE ACCESS TO OR CONTROL OVER THE COOKIES THEY USE, BUT YOU MAY BE ABLE TO OPT OUT OF SOME OF THEIR PRACTICES BY VISITING THE FOLLOWING LINKS: NETWORK ADVERTISING INITIATIVE, OMNITURE AND DIGITAL ADVERTISING ALLIANCE. PLEASE NOTE THAT OPTING OUT DOES NOT PREVENT THE DISPLAY OF ALL ADVERTISEMENTS TO YOU. WE MAY ALSO SHARE SOME NON-IDENTIFIABLE, DE-IDENTIFIED OR AGGREGATED INFORMATION FROM OR ABOUT YOU, SUCH AS LOCATION INFORMATION, WITH THIRD PARTIES IN CONNECTION WITH ADVERTISING PROGRAMS AND DATA ANALYTICS. YOU MAY BE ABLE TO LIMIT OUR SHARING OF SOME OF THIS INFORMATION THROUGH YOUR MOBILE DEVICE SETTINGS, AS DESCRIBED IN SECTION 2 ABOVE, OR THROUGH THE SERVICE&rsquo;S SETTINGS.</p>
            <p>B.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;SERVICE PROVIDERS: WE MAY RELY ON THIRD PARTY SERVICE PROVIDERS TO SUPPORT OR PROVIDE SERVICES FOR US IN CONNECTION WITH YOUR USE OF THE SERVICE, SUCH AS FOOD DELIVERY SERVICES, COMMUNICATIONS AND HOSTING, SECURITY AND FRAUD PREVENTION, TECHNICAL AND CUSTOMER SUPPORT, TRACKING AND REPORTING USAGE OF THE SERVICE, QUALITY ASSURANCE TESTING, PAYMENT PROCESSING, MARKETING, AND OTHER FUNCTIONS. WE MAY SHARE INFORMATION FROM OR ABOUT YOU WITH THESE THIRD PARTY PROVIDERS SO THAT THEY CAN PERFORM THEIR SERVICES OR COMPLETE YOUR REQUESTS. FOR EXAMPLE, WE MAY SHARE YOUR ADVERTISING IDENTIFIERS (E.G., YOUR IDFA, GAID, OR A CRYPTOGRAPHIC HASH OF YOUR EMAIL ADDRESS) WITH THIRD PARTY SERVICE PROVIDERS THAT HELP US FACILITATE FEAST&rsquo;s ADVERTISING PROGRAMS AND MEASURE AND REPORT THEIR EFFECTIVENESS, INCLUDING WITH THIRD PARTIES THAT HELP DETERMINE THE EFFICACY OF FEAST&rsquo;S ADVERTISING PROGRAMS BY COMBINING INFORMATION THEY RECEIVE ABOUT YOU FROM OTHER SOURCES THAN THE SERVICE. YOU MAY BE ABLE TO LIMIT OUR ACCESS TO SOME OF THIS INFORMATION THROUGH YOUR MOBILE DEVICE SETTINGS, AS DESCRIBED IN SECTION 2 ABOVE, OR THROUGH THE SERVICE&rsquo;S SETTINGS. FEAST&rsquo;S THIRD PARTY SERVICE PROVIDERS MAY LIKEWISE SHARE INFORMATION WITH US THAT THEY OBTAIN FROM OR ABOUT YOU IN CONNECTION WITH PROVIDING THEIR SERVICES OR COMPLETING YOUR REQUESTS.</p>
            <p>C.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;AGGREGATE OR ANONYMOUS INFORMATION: WE MAY SHARE USER INFORMATION IN THE AGGREGATE WITH THIRD PARTIES, SUCH AS BUSINESSES THAT ARE LISTED ON FEAST AND CONTENT DISTRIBUTORS. FOR EXAMPLE, WE MAY DISCLOSE THE NUMBER OF USERS THAT HAVE BEEN EXPOSED TO OR INTERACTED WITH ADVERTISEMENTS, OR THAT WE BELIEVE VISITED THE PHYSICAL LOCATION OF A PARTICULAR BUSINESS.</p>
            <p>D.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;USERS ON FEAST: WE MAY SHARE INFORMATION FROM OR ABOUT YOU (SUCH AS YOUR CITY, AND IF YOU PROVIDE IT, YOUR AGE AND GENDER), YOUR DEVICE TYPE, AND YOUR USE OF THE SERVICE (SUCH AS WHICH BUSINESSES YOU BOOKMARK OR CALL, OR IF YOU VISIT A BUSINESS&rsquo;S URL) WITH BUSINESSES ON FEAST. YOU MAY ADJUST YOUR ACCOUNT SETTINGS TO INCREASE OR DECREASE THE AMOUNT OF INFORMATION WE SHARE. KEEP IN MIND THAT BUSINESSES CAN ALSO VIEW YOUR PUBLIC ACTIVITY AND POSTS, AND MAY RECEIVE INFORMATION FROM OR ABOUT YOU WHEN YOU TRANSACT OR COMMUNICATE WITH THEM, THROUGH FEAST OR OTHERWISE, REGARDLESS OF YOUR SETTINGS (SEE SECTION 1 ABOVE). ADDITIONALLY, IF YOU MAKE A PHONE CALL TO A BUSINESS THROUGH OR IN CONNECTION WITH YOUR USE OF THE SERVICE, WE MAY SHARE INFORMATION ABOUT YOUR CALL WITH THE BUSINESS THAT THE BUSINESS WOULD HAVE RECEIVED HAD YOU CALLED THEM DIRECTLY, SUCH AS THE DATE AND TIME OF YOUR CALL AND YOUR PHONE NUMBER. YOU MAY BE ABLE TO LIMIT OUR ABILITY TO COLLECT AND SHARE YOUR PHONE NUMBER THROUGH YOUR PHONE SETTINGS OR PHONE SERVICE PROVIDER.</p>
            <p>E.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;INVESTIGATIONS AND LEGAL DISCLOSURES: WE MAY INVESTIGATE AND DISCLOSE INFORMATION FROM OR ABOUT YOU IF WE HAVE A GOOD FAITH BELIEF THAT SUCH INVESTIGATION OR DISCLOSURE: (A) IS REASONABLY NECESSARY TO COMPLY WITH LEGAL OR LAW ENFORCEMENT PROCESSES, SUCH AS A SEARCH WARRANT, SUBPOENA, STATUTE, JUDICIAL PROCEEDING, OR OTHER LEGAL PROCESS OR LAW ENFORCEMENT REQUEST; (B) IS HELPFUL TO PREVENT, INVESTIGATE, OR IDENTIFY POSSIBLE WRONGDOING IN CONNECTION WITH THE SERVICE; OR (C) PROTECTS OUR RIGHTS, REPUTATION, PROPERTY, OR THAT OF OUR USERS, AFFILIATES, OR THE PUBLIC, SUCH AS DISCLOSURES IN CONNECTION WITH FEAST&rsquo;S CONSUMER ALERTS PROGRAM. IF YOU FLAG OR OTHERWISE COMPLAIN TO US ABOUT CONTENT THROUGH THE SERVICE, WE MAY SHARE THE SUBSTANCE OF YOUR COMPLAINT WITH THE CONTRIBUTOR OF THAT CONTENT IN ORDER TO PROVIDE AN OPPORTUNITY FOR THE CONTRIBUTOR TO RESPOND.</p>
            <p>F.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;LINKS: THE SERVICE MAY LINK TO THIRD PARTY-CONTROLLED WEBSITES, LIKE A BUSINESS&rsquo;S URL. EXCEPT AS SET FORTH HEREIN, WE DO NOT SHARE YOUR PERSONAL INFORMATION WITH THEM, AND ARE NOT RESPONSIBLE FOR THEIR PRIVACY PRACTICES.</p>
            <p>G.&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;THIRD PARTY INTEGRATIONS: IF YOU SIGN UP FOR, OR LOG INTO, FEAST USING A THIRD PARTY SERVICE LIKE FACEBOOK OR GOOGLE, OR LINK YOUR FEAST ACCOUNT TO YOUR ACCOUNT WITH A THIRD PARTY SERVICE LIKE FACEBOOK, INSTAGRAM OR TWITTER, WE MAY RECEIVE INFORMATION ABOUT YOU FROM SUCH THIRD PARTY SERVICE. IF YOU POST CONTENT TO A THIRD PARTY SERVICE THROUGH THE SERVICE, THAT THIRD PARTY SERVICE WILL ALSO RECEIVE THAT CONTENT, WHICH WILL BE VISIBLE TO ANYONE THAT HAS ACCESS TO IT THROUGH THAT THIRD PARTY SERVICE. SOME OF OUR WEB PAGES UTILIZE FRAMING TECHNIQUES TO SERVE CONTENT TO YOU FROM OUR THIRD PARTY PARTNERS, WHILE PRESERVING THE LOOK AND FEEL OF THE SERVICE. IN SUCH CASES, PLEASE NOTE THAT THE INFORMATION YOU PROVIDE MAY BE TRANSMITTED DIRECTLY TO THE IDENTIFIED THIRD PARTY PARTNER. IF YOU INTERACT WITH BUSINESSES THROUGH FEAST, THEY WILL RECEIVE WHATEVER INFORMATION YOU CHOOSE TO SHARE WITH THEM, FOR EXAMPLE CONTACT INFORMATION YOU SHARE THROUGH DIRECT MESSAGES TO THE BUSINESS OR YOUR PHONE NUMBER IF YOU CALL THE BUSINESS.</p>
          </div>
          <p />

          <p>3. DATA RETENTION AND ACCOUNT TERMINATION</p>
          <p>YOU CAN CLOSE YOUR ACCOUNT BY EMAILING US AT SUPPORT@FEASTAPP.IO. WE WILL REMOVE YOUR PROFILE AND LISTS FROM VIEW AND/OR DISSOCIATE THEM FROM YOUR ACCOUNT PROFILE, BUT WE MAY RETAIN INFORMATION ABOUT YOU FOR THE PURPOSES AUTHORIZED UNDER THIS PRIVACY POLICY UNLESS PROHIBITED BY LAW. FOR EXAMPLE, WE MAY RETAIN INFORMATION TO PREVENT, INVESTIGATE, OR IDENTIFY POSSIBLE WRONGDOING IN CONNECTION WITH THE SERVICE OR TO COMPLY WITH LEGAL OBLIGATIONS. WE MAY ALSO MAINTAIN RESIDUAL COPIES OF YOUR PERSONAL INFORMATION IN OUR BACKUP SYSTEMS.</p>
          <p />

          <p>4. SECURITY</p>
          <p>WE USE VARIOUS SAFEGUARDS TO PROTECT THE PERSONAL INFORMATION SUBMITTED TO US, BOTH DURING TRANSMISSION AND AFTER WE RECEIVE IT. HOWEVER, NO METHOD OF TRANSMISSION OVER THE INTERNET OR VIA MOBILE DEVICE, OR METHOD OF ELECTRONIC STORAGE, IS 100% SECURE. THEREFORE, WHILE WE STRIVE TO USE COMMERCIALLY ACCEPTABLE MEANS TO PROTECT YOUR PERSONAL INFORMATION, WE CANNOT GUARANTEE ITS ABSOLUTE SECURITY.</p>
          <p />

          <p>5. CONTACT INFORMATION</p>
          <p>YOU MAY EMAIL US AT SUPPORT@FEASTAPP.IO CONCERNING OUR PRIVACY POLICY.</p>
          <p>FOR SECURITY PURPOSES, WE WILL TAKE SUCH STEPS AS WE DEEM NECESSARY TO CONFIRM YOUR IDENTITY BEFORE COMPLETING YOUR REQUEST OR SHARING ANY PERSONAL DATA WITH YOU.</p>
          <p />

          <p>6. MODIFICATIONS TO THIS PRIVACY POLICY</p>
          <p>WE MAY REVISE THIS PRIVACY POLICY FROM TIME TO TIME. THE MOST CURRENT VERSION OF THE PRIVACY POLICY WILL GOVERN OUR COLLECTION, USE, AND DISCLOSURE OF INFORMATION ABOUT YOU AND WILL BE LOCATED HERE. IF WE MAKE MATERIAL CHANGES TO THIS PRIVACY POLICY, WE WILL NOTIFY YOU BY EMAIL OR BY POSTING A NOTICE ON THE SERVICE PRIOR TO OR ON THE EFFECTIVE DATE OF THE CHANGES. BY CONTINUING TO ACCESS OR USE THE SERVICE AFTER THOSE CHANGES BECOME EFFECTIVE, YOU ACKNOWLEDGE THE REVISED PRIVACY POLICY.</p>
          <p />

          <p>7. CALIFORNIA RESIDENTS: YOUR CALIFORNIA PRIVACY RIGHTS</p>
          <p>UNDER THE CALIFORNIA CONSUMER PRIVACY ACT OF 2018 (&ldquo;CCPA&rdquo;), CALIFORNIA RESIDENTS HAVE CERTAIN RIGHTS AROUND FEAST&rsquo;S COLLECTION, USE, AND SHARING OF THEIR PERSONAL INFORMATION.</p>
          <p>FEAST DOES NOT SELL YOUR PERSONAL INFORMATION AND WILL NOT DO SO IN THE FUTURE WITHOUT PROVIDING YOU WITH NOTICE AND AN OPPORTUNITY TO OPT-OUT OF SUCH SALE AS REQUIRED BY LAW. SIMILARLY, WE DO NOT OFFER FINANCIAL INCENTIVES ASSOCIATED WITH OUR COLLECTION, USE, OR DISCLOSURE OF YOUR PERSONAL INFORMATION.</p>
          <p>FEAST COLLECTS VARIOUS CATEGORIES OF PERSONAL INFORMATION WHEN YOU USE THE SERVICE, INCLUDING IDENTIFIERS, COMMERCIAL INFORMATION, INTERNET OR OTHER ELECTRONIC NETWORK OR DEVICE ACTIVITY INFORMATION, GEOLOCATION DATA, AND PROFESSIONAL INFORMATION. A MORE DETAILED DESCRIPTION OF THE INFORMATION FEAST COLLECTS AND HOW WE USE IT IS PROVIDED ABOVE IN SECTION 1 (INFORMATION WE COLLECT AND HOW WE USE IT). SECTION 3 (THIRD PARTIES) DESCRIBES THE CATEGORIES OF THIRD PARTIES WITH WHOM WE SHARE PERSONAL INFORMATION, AND WHAT INFORMATION MAY BE SHARED UNDER DIFFERENT CIRCUMSTANCES.</p>
          <p>IF YOU ARE A RESIDENT OF CALIFORNIA, YOU HAVE THE RIGHT TO REQUEST TO KNOW WHAT PERSONAL INFORMATION HAS BEEN COLLECTED ABOUT YOU, AND TO ACCESS THAT INFORMATION. YOU ALSO HAVE THE RIGHT TO REQUEST DELETION OF YOUR PERSONAL INFORMATION, THOUGH EXCEPTIONS UNDER THE CCPA MAY ALLOW FEAST TO RETAIN AND USE CERTAIN PERSONAL INFORMATION NOTWITHSTANDING YOUR DELETION REQUEST. YOU MAY SEND YOUR REQUEST BY EMAIL TO SUPPORT@FEASTAPP.IO. WE WILL NOT DISCRIMINATE AGAINST YOU FOR EXERCISING YOUR RIGHTS UNDER THE CCPA. AN AGENT MAY SUBMIT A REQUEST ON YOUR BEHALF, BUT YOU MUST VERIFY THAT YOUR AGENT IS AUTHORIZED TO DO SO.</p>
          <p>SEPARATE FROM THE CCPA, CALIFORNIA&rsquo;S SHINE THE LIGHT LAW GIVES CALIFORNIA RESIDENTS THE RIGHT TO ASK COMPANIES WHAT PERSONAL INFORMATION THEY SHARE WITH THIRD PARTIES FOR THOSE THIRD PARTIES&rsquo; DIRECT MARKETING PURPOSES. WE DO NOT DISCLOSE YOUR PERSONAL INFORMATION TO THIRD PARTIES FOR THE PURPOSE OF DIRECTLY MARKETING THEIR GOODS OR SERVICES TO YOU UNLESS YOU REQUEST SUCH DISCLOSURE. IF YOU HAVE ANY QUESTIONS REGARDING THIS POLICY, OR WOULD LIKE TO CHANGE YOUR PREFERENCES, YOU MAY CONTACT US AT THE ADDRESS LISTED ABOVE IN SECTION 8.</p>
          <p />

          <p>8. NEVADA RESIDENTS: YOUR NEVADA PRIVACY RIGHTS</p>
          <p>NEVADA LAW (SB 220) REQUIRES WEBSITE OPERATORS TO PROVIDE A WAY FOR NEVADA CONSUMERS TO OPT OUT OF THE SALE OF CERTAIN INFORMATION THAT THE WEBSITE OPERATOR MAY COLLECT ABOUT THEM. FEAST DOES NOT SELL YOUR PERSONAL INFORMATION TO THIRD PARTIES AS DEFINED IN NEVADA LAW, AND WILL NOT DO SO IN THE FUTURE WITHOUT PROVIDING YOU WITH NOTICE AND AN OPPORTUNITY TO OPT-OUT OF SUCH SALE AS REQUIRED BY LAW. IF YOU HAVE ANY QUESTIONS REGARDING OUR DATA PRIVACY PRACTICES OR OUR COMPLIANCE WITH NEVADA DATA PRIVACY LAW, PLEASE CONTACT US AT SUPPORT@FEASTAPP.IO.</p>
          <p><br /></p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Privacy
